// Matches.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Matches() {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    // Fetch profiles from the server
    axios.get('/api/profiles')
      .then(response => {
        setProfiles(response.data);
      })
      .catch(error => {
        console.error('Error fetching profiles:', error);
      });
  }, []);

  return (
	<div className="matches-container">
    <div className="matches">
      <div className="profile-list">
        {profiles.map(profile => (
          <Link to={`/matches/profile/${profile._id}`} key={profile._id} className="profile-link">
            <div className="profile-card">
              <div className="profile-picture">
                <img src={`/uploads/${profile.profilePicture}`} alt={profile.name} />
              </div>
              <div className="profile-info">
                <h3 className="profile-name">{profile.name}</h3>
                <p className="profile-experience">Fitness Experience: {profile.fitnessExperience}</p>
                <p className="profile-availability">Availability: {profile.availability}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
	</div>
  );
}

export default Matches;
