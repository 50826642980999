import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
// import { PageLoader } from "./page-loader";


//TODO REFACTOR THIS ALSO ADD IT TO CREATE AND MATCH ROUTES
// TODO IT DOESENT WORK YET


export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="redirect-page">
        <h1>Redirecting...</h1>
      </div>
    ),
  });

  return <Component />;
};