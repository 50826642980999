import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

//https://auth0.com/docs/quickstart/spa/react/02-calling-an-api
// useEffect(() => {
//     const getUserMetadata = async () => {
//       const domain = "dev-82eehfmqdnjo70bp.us.auth0.com";
  
//       try {
//         const accessToken = await getAccessTokenSilently({
//           authorizationParams: {
//             audience: `https://${domain}/api/v2/`,
//             scope: "read:current_user",
//           },
//         });
  
//         const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
//         const metadataResponse = await fetch(userDetailsByIdUrl, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });
  
//         const { user_metadata } = await metadataResponse.json();
  
//         setUserMetadata(user_metadata);
//       } catch (e) {
//         console.log(e.message);
//       }
//     };
  
//     getUserMetadata();
//   }, [getAccessTokenSilently, user?.sub]);


const NavProfile = () => {
    

  const { user, isAuthenticated, isLoading } = useAuth0();
  if(!isAuthenticated){
    return <div><h2>Not authenticated</h2></div>
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    
    isAuthenticated && (
      <div>
        <div><h2>Authenticated</h2></div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
      </div>
    )
  );
};

export default NavProfile;