import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';

//For router
import Home from './Home';
import CreateProfile from './CreateProfile';
import Matches from './Matches';
import Profile from './Profile'; 
import { LogoutButton } from './logout';
import { LoginButton } from './login';
import { CallbackPage } from "./callbackPage";
import { AuthenticationGuard } from './authGaurd';

// import { createRoot } from 'react-dom/client';
// import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
//require('dotenv').config();

import { Auth0ProviderWithHistory } from './Auth0ProviderWrapper';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));



//<Route path="/matches/" element={<Matches />} />
//<Route path="/callback" element={<CallbackPage />} />

root.render(

    <Router>
        <Auth0ProviderWithHistory>
        <App>
          
        </App>
        <Routes>
          <Route path="/matches" 
          element={<AuthenticationGuard component={Matches} />} 
          />
          <Route path="/create" 
          element={<AuthenticationGuard component={CreateProfile} />} 
          />
          <Route path="/matches/profile/:id"
          element={<AuthenticationGuard component={Profile} />} 
          />
          <Route path="/login" element={<LoginButton/>} />
          <Route path="/logout" element={<LogoutButton />} />
          <Route path="/callback"
          element={<AuthenticationGuard component={CallbackPage} />} 
          />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
        </Auth0ProviderWithHistory>
    </Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
