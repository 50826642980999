import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserRouter as  Route, Link } from 'react-router-dom';
import axios from 'axios';

function Profile() {
    const [profile, setProfile] = useState(null);
    const { id } = useParams();
  
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`/api/profiles/${id}`);
          setProfile(response.data);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };
  
      fetchProfile();
    }, [id]);
  
    if (!profile) {
      return <div>Loading...</div>;
    }

    const handleMatch = async () => {
      alert("Match with " + profile.name + "! You can text them at " + profile.phoneNumber + ", please be polite and let them know your from spottr!");
    };

    return (
        <div className="profile-container">
            <Link to="/matches">&#8592; Back</Link>
            <div className="profile-content">
                <h2 className="profile-name">{profile.name}</h2>
                <div className="profile-header">
                    <div className="profile-profile-picture">
                        <img src={`/uploads/${profile.profilePicture}`} alt={profile.name} />
                    </div>
                </div>
                <div className="profile-details">
                    <p className="profile-experience">Fitness Experience: {profile.fitnessExperience}</p>
                    <p className="profile-bio">Bio: {profile.bio}</p>
                    <p className="profile-availability">Availability: {profile.availability}</p>
                </div>
                <button className="match-button" onClick={handleMatch}>Match</button>
            </div>
        </div>
    );
}

export default Profile;
