// Updated JSX for Create Profile page
import React, { useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react"; // for email


const CreateProfile = () => {
    const { user, isAuthenticated} = useAuth0();
    const [profileData, setProfileData] = useState({
        name: "",
        fitnessExperience: "",
        bio: "",
        availability: "",
        profilePicture: null,
        phoneNumber: "",
        email: user ? user.email : ""
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProfileData({ ...profileData, [name]: value });
    };

    const handleFileChange = (event) => {
        setProfileData({ ...profileData, profilePicture: event.target.files[0] });
    };

    const handleProfileSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("name", profileData.name);
            formData.append("fitnessExperience", profileData.fitnessExperience);
            formData.append("bio", profileData.bio);
            formData.append("availability", profileData.availability);
            formData.append("profilePicture", profileData.profilePicture);
            formData.append("phoneNumber", profileData.phoneNumber);
            formData.append("email", profileData.email);

            const response = await axios.post(
                "https://spottr.org/api/profiles",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 201) {
                setProfileData({
                    name: "",
                    fitnessExperience: "",
                    bio: "",
                    availability: "",
                    profilePicture: null,
                    phoneNumber: "",
                    email: user ? user.email : ""
                });
                window.location.href = "https://spottr.org/matches";
            } else if (response.status === 413) {
                alert("Upload Too Large, Please Try Again");
                console.error("Error creating profile:", response.statusText);
            }
        } catch (error) {
            console.error("Error creating profile:", error);
        }
    };

    return (
        <div className="create-profile">
            <h2>Create Your Profile!</h2>
			<h3>Other users will be able to see you name, experience level, bio, and availability, only matches will see your phone number</h3>
			<p>First Name</p>
            <input
                type="text"
                name="name"
                placeholder="eg Jess or Sam"
                value={profileData.name}
                onChange={handleInputChange}
            />
			<p>Fitness Experience</p>
            <div>
                <label>
                    None
                    <input
                        type="radio"
                        name="fitnessExperience"
                        value="None"
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    Beginner (0-1 year)
                    <input
                        type="radio"
                        name="fitnessExperience"
                        value="Beginner"
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    Intermediate (1-3 years)
                    <input
                        type="radio"
                        name="fitnessExperience"
                        value="Intermediate"
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    Expert (3+ years)
                    <input
                        type="radio"
                        name="fitnessExperience"
                        value="Expert"
                        onChange={handleInputChange}
                    />
                </label>
            </div>
			<p>Bio</p>
            <textarea
                name="bio"
                placeholder="eg I love cardio and just want somebody to hangout with or I am working on hitting two plates for bench and need a spottr"
                value={profileData.bio}
                onChange={handleInputChange}
            ></textarea>
			<p>Availability</p>
            <textarea
                name="availability"
                placeholder="eg Everyday at 5 or MWF 2-3 and TTh 5-6"
                value={profileData.availability}
                onChange={handleInputChange}
            ></textarea>
			<p>Profile Picture</p>
            <input
                type="file"
                name="profilePicture"
                onChange={handleFileChange}
            />
			<p>Phone Number</p>
			<p>Your number remains private until somebody matches with you, and it will only be shared with the person matching with you!</p>
            <input
                type="text"
                name="phoneNumber"
                placeholder="303-123-4567"
                value={profileData.phoneNumber}
                onChange={handleInputChange}
            />
            <input type="hidden" name="email" value={profileData.email} />
            <button onClick={handleProfileSubmit}>Create Profile</button>
        </div>
    );
};

export default CreateProfile;
