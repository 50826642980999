// AuthButton.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from './login';
import { LogoutButton } from './logout';
import { SignupButton } from './signup';

export const AuthButton = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="auth-button">
      {!isAuthenticated && (
        <>
          <SignupButton />
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <>
          <LogoutButton />
        </>
      )}
    </div>
  );
};