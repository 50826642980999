// App.js

import React from 'react';
import { BrowserRouter as  Route, Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { AuthButton } from './AuthButton';
import './App.css';

// import Home from './Home';
// import CreateProfile from './CreateProfile';
// import Matches from './Matches';
// import Profile from './Profile'; 
// import { LogoutButton } from './logout';
// import { LoginButton } from './login';
// import { CallbackPage } from "./callbackPage";
// import NavProfile from './NavProfile';


export const App = () => {

    const { isLoading, isAuthenticated } = useAuth0();

    if (isLoading) {
      return (
        <div className="page-layout">
            <h1>Loading...</h1>
        </div>
      );
    }


    return (
            <div className="app">
                		{!isAuthenticated && (
                            <nav>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <AuthButton></AuthButton>
                                    </li>
                                </ul>
                            </nav>
                        )}
                        {isAuthenticated && (
                            <nav>
                                <ul>
                                    <li>
                                        <Link to="/matches">Matches</Link>
                                    </li>
                                    <li>
                                        <Link to="/create">Create Profile</Link>
                                    </li>
                                    <li>
                                        <AuthButton></AuthButton>
                                    </li>
                                </ul>
                            </nav>
                        )}
            </div>
    );
};
