// Home.js

//import React, { useState, useEffect } from "react";
//import axios from "axios";
import Profile from './NavProfile';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from './login';
import { SignupButton } from './signup';
import { LogoutButton } from './logout';

function Home() {
	const { isAuthenticated } = useAuth0();
  
	return (
	  <div className="home">
		<img src="SpottrLogo.png" alt="SpottrLogo" />
		<div className="home-content">
		  {!isAuthenticated && (
			<>
			  <h1>Welcome to Spottr!</h1>
			  <h3>Our goal is to help you find your gym buddy!</h3>
			  <div className="notice">
			  <h2>We are currenly working to fix of a bug that makes some users have to sign in twice, if sign in is not working please sign in again!!!</h2>
			  </div>
			  <h3>Get started by signing up and creating an account!</h3>
			  <SignupButton />
			  <h3>Already have an account? Sign in!</h3>
			  <LoginButton />
			</>
		  )}
		  {isAuthenticated && (
			<>
			  <h2>Thank you for signing up for Spottr, let's find your gym buddy!</h2>
			  <h3>Please see the matches page!</h3>
			  <Profile />
			</>
		  )}
		</div>
	  </div>
	);
  }
  
  export default Home;
  