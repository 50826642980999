import React from "react";
import Profile from './NavProfile';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from './login';
import { SignupButton } from './signup';


export const CallbackPage = () => {
  const { isAuthenticated } = useAuth0();
  return (
    
    <div className="callback-page">
      <h3>CALLBACK PAGE</h3>
      {!isAuthenticated && (
			<>
			  <h2>Something went wrong... please try to login again</h2>
        {/* <Profile></Profile> */}
			  <SignupButton />
			  <h3>Already have an account? Sign in!</h3>
			  <LoginButton />
			</>
		  )}
		  {isAuthenticated && (
			<>
			  <h2>Something went wrong... </h2>
			  <h3>Please see the matches page!</h3>
			  {/* <Profile /> */}
			</>
		  )}
    </div>
  );
};