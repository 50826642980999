// Auth0ProviderWrapper.js
//https://developer.auth0.com/resources/guides/spa/react/basic-authentication

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_ISSUER;
  const clientId = process.env.REACT_APP_CLIENTID;
  const redirectUri = process.env.REACT_APP_CALLBACK_URL;
  const baseUri = process.env.REACT_APP_BASEURL

  // https://stackoverflow.com/questions/73214442/react-application-not-correctly-recognizing-authenticated-state-after-auth0-logi


  const onRedirectCallback = (appState) => {
    console.log("App State:");
    console.log(appState);
    //navigate(appState?.returnTo || window.location.pathname);
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

//   if (!(domain && clientId && redirectUri)) {
//     return null;
//   }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
